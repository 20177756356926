import React from 'react';
import { graphql, Link } from 'gatsby';

const TestPage = ({ data }) => (
  <div className="container">
    <br></br>
    <br></br>
    <h1>WordPress Blog</h1>
    <h4>Posts</h4>
    <br></br>
    <br></br>
    {data.allWordpressPost.nodes.map((node) => (
      <div>
        {console.log(node)}
        <Link to={node.slug}>
          <h4>{node.title}</h4>
        </Link>
        <br></br>
        <div dangerouslySetInnerHTML={{ __html: node.excerpt }} />
      </div>
    ))}
  </div>
);

export default TestPage;

export const pageQuery = graphql`
  query {
    allWordpressPost(sort: { fields: [date] }) {
      nodes {
        title
        excerpt
        slug
      }
    }
  }
`;
